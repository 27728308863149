import { CreditAgency } from "./enum";

export enum TokenResponseResult {
	/** トークン取得に成功した場合 */
	OK = "OK",
	/** トークン取得に失敗した場合 */
	NG = "NG"
}

/** 初期化成功時のSB PAYMENTのレスポンス */
export interface CreditCardSbPaymentInitData {
	agency: CreditAgency.SB_PAYMENT;
	merchant_id: string;
	service_id: string;
}

/** SB PAYMENTトークン取得時のカード情報オブジェクト */
export interface TokenRequest {
	/** SbPayment発行の加盟店コード */
	merchantId: string;
	/** SbPayment発行のサービスコード */
	serviceId: string;
	/** カード番号 */
	ccNumber: string | number;
	/**
	 * カード有効期限
	 * YYYYMM
	 */
	ccExpiration: string;
	/** セキュリティーコード */
	securityCode?: string | number;
}

/** トークン取得成功時のコールバック関数の引数オブジェクト */
export interface TokenResponseSuccess {
	result: TokenResponseResult.OK;
	tokenResponse: {
		/** トークン */
		token: string;
		/** トークンキー */
		tokenKey: string;
	};
}

/** トークン取得失敗時のコールバック関数の引数オブジェクト */
export interface TokenResponseFailure {
	result: TokenResponseResult.NG;
	errorCode: string;
}

/** `com_sbps_system.generateToken`関数のコールバックオブジェクト */
export type GetTokenCallbackObject = TokenResponseSuccess | TokenResponseFailure;

/** SBペイメント提供js内の関数定義 */
export interface ComSbPaymentSystem {
	generateToken: (
		tokenRequest: TokenRequest,
		callback: (response: GetTokenCallbackObject) => Promise<void>
	) => void;
}

// ------------------------------

export interface TdsTokenRequest {
	merchantId: string;
	serviceId: string;
	billingLastName: string;
	billingFirstName: string;
	billingPostalCode?: string;
	billingCity?: string;
	billingAddress1?: string;
	billingAddress2?: string;
	billingAddress3?: string;
	/** 請求先電話番号, メールアドレスを送信しない場合必須 */
	billingPhone?: string;
	workPhone?: string;
	shippingPostalCode?: string;
	shippingCity?: string;
	shippingAddress1?: string;
	shippingAddress2?: string;
	shippingAddress3?: string;
	/** メールアドレス, 請求先電話番号を利用しない場合必須 */
	email: string;
}

export interface TdsTokenResponseSuccess {
	result: TokenResponseResult.OK;
	tokenResponse: {
		/** カード利用者決済情報トークン */
		tds2infoToken: string;
		/** カード利用者決済情報トークンキー */
		tds2infoTokenKey: string;
	} & TdsTokenRequest;
}

export interface TdsTokenResponseFailure {
	result: TokenResponseResult.NG;
	errorCode: string;
}

export type TdsTokenResponse = TdsTokenResponseSuccess | TdsTokenResponseFailure;

/** SBペイメント提供の3Dセキュア用トークン取得js */
export interface ComSbpsSystemTds2 {
	generateToken: (
		request: TdsTokenRequest,
		callback: (response: TdsTokenResponse) => Promise<void> | void
	) => void;
}
