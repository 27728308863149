// import { io } from "socket.io-client";
// import {
// 	CreditCardJsInitResponse,
// 	DoThreeDomainSecureAuthOnSuccess,
// 	DoThreeDomainSecureAuthOnFailure,
// 	DoThreeDomainSecureAuthReturn,
// 	PaymentRegistrationSuccessYamatoResponse,
// 	DoThreeDomainSecureAuthIncomingMessage,
// 	PaymentSystemReturnStatus
// } from "../../../types/v1/credit_card";
// import { CreditCardYamatoInitData } from "../../../types/v1/credit_card/yamato";
// import { urls } from "..";

// export const yamatoDoThreeDomainSecureAuth = async (
// 	res: PaymentRegistrationSuccessYamatoResponse,
// 	settings: CreditCardJsInitResponse<CreditCardYamatoInitData>,
// 	onSuccess?: DoThreeDomainSecureAuthOnSuccess,
// 	onFailure?: DoThreeDomainSecureAuthOnFailure
// ): Promise<DoThreeDomainSecureAuthReturn> => {
// 	console.log(res, settings);

// 	const htmlContent = res.three_domain_secure_auth_url
// 		.replace("<![CDATA[", "")
// 		.replace("]]>", "");

// 	const iframe = document.createElement("iframe");

// 	iframe.style.position = "fixed";
// 	iframe.style.top = "0";
// 	iframe.style.left = "0";
// 	iframe.style.width = "100%";
// 	iframe.style.height = "100%";
// 	iframe.style.border = "none";
// 	iframe.style.zIndex = "9999"; // 必要に応じて z-index を調整

// 	document.body.appendChild(iframe);

// 	// iframe内にHTMLを挿入
// 	const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
// 	if (iframeDocument) {
// 		iframeDocument.open();
// 		iframeDocument.write(htmlContent);
// 		iframeDocument.close();
// 	}

// 	return new Promise<DoThreeDomainSecureAuthReturn>((resolve, reject) => {
// 		/** WebSocketのインスタンス */
// 		const socket = io(`${urls.PROTOCOL}://${urls.SERVER_DOMAIN}${urls.CREDIT_WEB_SOCKET}`, {
// 			query: { transaction_id: res.transaction_id, return_at: res.return_at }
// 		});

// 		socket.on("connect", () => {
// 			console.log("WebSocket接続完了");
// 		});

// 		socket.on("connect_error", error => {
// 			console.error("WebSocket接続失敗:", error);

// 			if (onFailure) {
// 				onFailure([]);
// 			}
// 			reject(error);
// 		});

// 		// 3Dセキュア認証結果受け取り
// 		socket.on("3dsResult", (result: DoThreeDomainSecureAuthIncomingMessage) => {
// 			console.log("3Dセキュア認証結果", result);

// 			const returnObject: DoThreeDomainSecureAuthReturn = {
// 				...result,
// 				status: PaymentSystemReturnStatus.SUCCESS
// 			};

// 			if (onSuccess) {
// 				onSuccess(returnObject);
// 			}

// 			resolve(returnObject);
// 		});
// 	});
// };

import { io } from "socket.io-client";
import { PaymentSystemReturnStatus } from "../../../types/v1/credit_card";
import { CreditCardYamatoInitData } from "../../../types/v1/credit_card/yamato";
import { urls } from "..";
import { CreditCardJsInitResponse } from "../../../types/v1/credit_card/get_token";
import {
	PaymentRegistrationSuccessYamatoResponse,
	DoThreeDomainSecureAuthOnSuccess,
	DoThreeDomainSecureAuthOnFailure,
	DoThreeDomainSecureAuthReturn,
	DoThreeDomainSecureAuthIncomingMessage
} from "../../../types/v1/credit_card/do_three_domain_secure_auth";

export const yamatoDoThreeDomainSecureAuth = async (
	res: PaymentRegistrationSuccessYamatoResponse,
	settings: CreditCardJsInitResponse<CreditCardYamatoInitData>,
	options: { popupWindow: WindowProxy | null },
	onSuccess?: DoThreeDomainSecureAuthOnSuccess,
	onFailure?: DoThreeDomainSecureAuthOnFailure
): Promise<DoThreeDomainSecureAuthReturn> => {
	console.log(res, settings);

	const htmlContent = res.three_domain_secure_auth_url
		.replace("<![CDATA[", "")
		.replace("]]>", "");

	let newTab = options.popupWindow;
	if (!newTab) {
		// options.popupWindow が未指定の場合に新しいウィンドウを開く
		newTab = window.open("", "_blank");
	}

	if (newTab) {
		// 新しいウィンドウにHTMLを挿入
		newTab.document.open();
		newTab.document.write(htmlContent);
		newTab.document.close();
	} else {
		console.error(
			"新しいウィンドウを開けませんでした。ポップアップブロッカーを確認してください。"
		);
	}

	return new Promise<DoThreeDomainSecureAuthReturn>((resolve, reject) => {
		/** WebSocketのインスタンス */
		const socket = io(`${urls.PROTOCOL}://${urls.SERVER_DOMAIN}${urls.CREDIT_WEB_SOCKET}`, {
			query: { transaction_id: res.transaction_id, return_at: res.return_at }
		});

		socket.on("connect", () => {
			console.log("WebSocket接続完了");
		});

		socket.on("connect_error", error => {
			console.error("WebSocket接続失敗:", error);

			socket.disconnect();

			if (onFailure) {
				onFailure([]);
			}
			reject(error);
		});

		// 3Dセキュア認証結果受け取り
		socket.on("3dsResult", (result: DoThreeDomainSecureAuthIncomingMessage) => {
			console.log("3Dセキュア認証結果", result);

			if (newTab) {
				// 認証結果を受け取った後、タブを閉じる
				newTab.close();
			}

			const returnObject: DoThreeDomainSecureAuthReturn = {
				...result,
				status: PaymentSystemReturnStatus.SUCCESS
			};

			socket.disconnect();

			if (onSuccess) {
				onSuccess(returnObject);
			}

			resolve(returnObject);
		});
	});
};
