export const sbPaymentEndpoints = {
	generateToken: {
		TEST: "https://stbtoken.sps-system.com/sbpstoken/com_sbps_system_token.js",
		PRODUCTION: "https://token.sps-system.com/sbpstoken/com_sbps_system_token.js"
	},
	generateTdsToken: {
		TEST: "https://stbtoken.sps-system.com/sbpstoken/com_sbps_system_tds2infotoken.js",
		PRODUCTION: "https://token.sps-system.com/sbpstoken/com_sbps_system_tds2infotoken.js"
	}
};
