import { PaymentSystemError, Code } from "../../../types/v1/credit_card";
import { CreditCardJsInitResponse, TokenError } from "../../../types/v1/credit_card/get_token";
import { commonErrors, getErrorObjects } from "./commonError";

export enum TokenErrorCode {
	/** カード番号が未入力 */
	CARD_NUMBER_REQUIRED = "SCT000001",
	/** カード番号に利用できない値があります */
	INVALID_CARD_NUMBER_VALUE = "SCT000002",
	/** カード番号の桁数が不正 */
	INVALID_CARD_NUMBER_LENGTH = "SCT000003",
	/** カード名義が未入力 */
	CARD_NAME_REQUIRED = "SCT000004",
	/** カード名義の桁数が多すぎます */
	CARD_NAME_TOO_LONG = "SCT000005",
	/** カード名義に不正な値が含まれています */
	INVALID_CARD_NAME_VALUE = "SCT000006",
	/** 有効期限（年）が未入力 */
	EXPIRATION_YEAR_REQUIRED = "SCT000007",
	/** 有効期限（年）の値が不正 */
	INVALID_EXPIRATION_YEAR_VALUE = "SCT000008",
	/** 有効期限（年）の値が過去日など、不正な値 */
	PAST_EXPIRATION_YEAR = "SCT000009",
	/** 有効期限（月）が未入力 */
	EXPIRATION_MONTH_REQUIRED = "SCT000010",
	/** 有効期限（月）の値が不正 */
	INVALID_EXPIRATION_MONTH_VALUE = "SCT000011",
	/** 有効期限（月）の値が存在しない月など */
	INVALID_EXPIRATION_MONTH = "SCT000012",
	/** 有効期限が不正 */
	INVALID_EXPIRATION_DATE = "SCT000013",
	/** 有効期限が未入力 */
	EXPIRATION_DATE_REQUIRED = "SCT000014",
	/** 有効期限の値が不正 */
	INVALID_EXPIRATION_DATE_VALUE = "SCT000015",
	/** 有効期限が過去日 */
	EXPIRATION_DATE_PAST = "SCT000016",
	/** セキュリティーコードが未入力 */
	SECURITY_CODE_REQUIRED = "SCT000017",
	/** セキュリティーコードが不正 */
	INVALID_SECURITY_CODE = "SCT000018",
	/** セキュリティーコードが 3 または 4 桁以外 */
	INVALID_SECURITY_CODE_LENGTH = "SCT000019",
	/** CONTENT-TYPE が 'text/xml' もしくは 'application/xml' 以外 */
	INVALID_CONTENT_TYPE = "SCT000021",
	/** CONTENT-LENGTH が存在しないか、0 が指定されています */
	MISSING_OR_ZERO_CONTENT_LENGTH = "SCT000022",
	/** CONTENT-LENGTH が 1024 byte より大きい */
	CONTENT_LENGTH_TOO_LARGE = "SCT000023",
	/** XML データが未送信 */
	XML_DATA_NOT_SENT = "SCT000024",
	/** XML データが 1024 byte より大きい */
	XML_DATA_TOO_LARGE = "SCT000025",
	/** XML データに構文エラーがあります */
	XML_SYNTAX_ERROR = "SCT000026",
	/** XML の action が空 */
	EMPTY_XML_ACTION = "SCT000027",
	/** 無効な action が指定されています */
	INVALID_XML_ACTION = "SCT000028",
	/** XML に authentication > clientip が存在しない */
	MISSING_AUTH_CLIENTIP = "SCT000029",
	/** authentication > clientip のフォーマットが不正 */
	INVALID_AUTH_CLIENTIP_FORMAT = "SCT000030",
	/** 不正な authentication > clientip が指定されています */
	INVALID_AUTH_CLIENTIP = "SCT000031",
	/** 不正な authentication > clientip が指定されています */
	INVALID_AUTH_CLIENTIP_REPEAT = "SCT000032",
	/** 加盟店コードをセットしてください */
	MISSING_MERCHANT_CODE = "SCT000033",
	/** 正しい加盟店コードをセットしてください */
	INVALID_MERCHANT_CODE = "SCT000034",
	/** 正しい加盟店コードをセットしてください */
	INVALID_MERCHANT_CODE_REPEAT = "SCT000035",
	/** 認証区分をセットしてください */
	MISSING_AUTHENTICATION_TYPE = "SCT000036",
	/** 正しい認証区分をセットしてください */
	INVALID_AUTHENTICATION_TYPE = "SCT000037",
	/** オプションサービス区分をセットしてください */
	MISSING_OPTION_SERVICE_TYPE = "SCT000038",
	/** 正しいオプションサービス区分をセットしてください */
	INVALID_OPTION_SERVICE_TYPE = "SCT000039",
	/** 会員IDをセットしてください */
	MISSING_MEMBER_ID = "SCT000040",
	/** 正しい会員IDをセットしてください */
	INVALID_MEMBER_ID = "SCT000041",
	/** 正しい会員IDをセットしてください */
	INVALID_MEMBER_ID_REPEAT = "SCT000042",
	/** 認証キーをセットしてください */
	MISSING_AUTH_KEY = "SCT000043",
	/** 正しい認証キーをセットしてください */
	INVALID_AUTH_KEY = "SCT000044",
	/** チェックサムをセットしてください */
	MISSING_CHECKSUM = "SCT000046",
	/** 正しいチェックサムをセットしてください */
	INVALID_CHECKSUM = "SCT000047",
	/** 正しいカード識別キーをセットしてください */
	INVALID_CARD_IDENTIFIER_KEY = "SCT000048",
	/** 正しい最終利用日時をセットしてください */
	INVALID_LAST_USED_DATE = "SCT000049",
	/** 正しい URL 形式にてリクエストしてください */
	INVALID_URL_FORMAT = "SCT000050",
	/** 正しい認証区分にてリクエストしてください */
	INVALID_AUTHENTICATION_TYPE_REQUEST = "SCT000051",
	/** オプションサービスの契約または、オプションサービス区分を確認してください */
	CHECK_OPTION_SERVICE_CONTRACT = "SCT000052",
	/** カード情報がありません。会員番号等を確認してください */
	MISSING_CARD_INFO = "SCT000053",
	/** トークンの発行が出来ません。リクエスト内容を確認してください */
	TOKEN_ISSUE_FAILED_CHECK = "SCT000054",
	/** クレジット決済は現在ご利用いただけません */
	CREDIT_PAYMENT_NOT_AVAILABLE = "SCT000055",
	/** MPクレカトークン発行数フォーマットエラー(数字以外を含む) */
	TOKEN_ISSUE_FORMAT_ERROR_INVALID_CHARACTERS = "SCT000056",
	/** MPクレカトークン発行数フォーマットエラー(1-10の範囲外) */
	TOKEN_ISSUE_FORMAT_ERROR_OUT_OF_RANGE = "SCT000057",
	/** カード情報を暗号化した情報必須チェックエラー */
	ENCRYPTED_CARD_INFO_REQUIRED = "SCT000058",
	/** カード情報(Encrypted)が復号できない */
	DECRYPTION_FAILED = "SCT000059",
	/** カード情報(Encrypted)復号化後フォーマットエラー */
	DECRYPTION_FORMAT_ERROR = "SCT000060",
	/** callback必須チェックエラー */
	CALLBACK_REQUIRED_ERROR = "SCT000061",
	/** callbackフォーマットエラー(半角英数字、アンダースコア、ピリオド以外を含む) */
	CALLBACK_FORMAT_ERROR = "SCT000062",
	/** MPクレカトークン用パラメーター(cardObject)が存在しない */
	MISSING_CARD_OBJECT = "SCT000063",
	/** マーチャント ID フォーマットエラー */
	MERCHANT_FORMAT_ERROR = "SCT000064",
	/** サービス ID フォーマットエラー */
	SERVICE_ID_FORMAT_ERROR = "SCT000065",
	/** SBペイメント利用時、options.purchaseInfoがない場合のエラー */
	UNDEFINED_PURCHASE_INFO = "SCT000066",
	/** 存在しないidが指定された */
	UNDEFINED_ID = "SCT999997",
	/** 不明なエラー */
	UNKNOWN_ERROR = "SCT999998",
	/** 決済代行からの未知のエラーコードによるエラー */
	UNKNOWN_AGENCY_ERROR = "SCT999999"
}

/**
 * 不明なエラーが発生した場合
 * @param e エラーオブジェクト
 * @returns エラーオブジェクトをstringに変換して `SCT999998` エラーとして返す
 */
export const unknownTokenError = (e: unknown): TokenError[] => {
	return [
		{
			code: TokenErrorCode.UNKNOWN_ERROR,
			item: `${String(e)}`,
			description: "不明なエラーが発生しました"
		}
	];
};

const tokenUniqueErrors: PaymentSystemError[] = [
	{
		code: TokenErrorCode.UNDEFINED_ID,
		item: "card_id",
		description: "存在しないクレジットカードidが指定されています。"
	},
	{
		code: TokenErrorCode.UNKNOWN_AGENCY_ERROR,
		item: "",
		description: "決済代行でエラーが発生しました。"
	},
	{
		code: TokenErrorCode.UNDEFINED_PURCHASE_INFO,
		item: "options.purchaseInfo",
		description: "ご利用中の決済代行の場合、トークン取得時に購入者情報は必須です。"
	}
];

/**
 * 決済代行ごとのトークン取得時のエラーマッピング
 *
 * 共通するものはkeyを増やし、対象の決済代行のエラーコード種別を配列で追加してください
 * @remarks
 * 共通エラーコード区分：`SCT` Script CreditCard Token
 */
export const tokenCommonErrors: TokenError[] = [
	...commonErrors,
	...[
		{
			code: TokenErrorCode.CARD_NUMBER_REQUIRED,
			item: "card.number",
			description: "カード番号が未入力です",
			ZEUS: ["02130514"],
			YAMATO_WEB_COLLECT: ["Y021010901"],
			GMO: ["100"],
			SB_PAYMENT: ["03003"]
		},
		{
			code: TokenErrorCode.INVALID_CARD_NUMBER_VALUE,
			item: "card.number",
			description: "カード番号に利用できない値があります",
			ZEUS: ["02130517"],
			YAMATO_WEB_COLLECT: ["Y021010904"],
			GMO: ["101"],
			SB_PAYMENT: ["07003"]
		},
		{
			code: TokenErrorCode.INVALID_CARD_NUMBER_LENGTH,
			item: "card.number",
			description: "カード番号を正しく入力してください",
			ZEUS: ["02130619", "02130620", "02130621", "02130640"],
			YAMATO_WEB_COLLECT: ["Y021010902"],
			GMO: ["102"],
			SB_PAYMENT: ["05003"]
		},
		{
			code: TokenErrorCode.CARD_NAME_REQUIRED,
			item: "card.name",
			description: "カード名義が未入力です",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021011001"]
		},
		{
			code: TokenErrorCode.CARD_NAME_TOO_LONG,
			item: "card.name",
			description: "カード名義の桁数が多すぎます",
			ZEUS: ["02131124"],
			YAMATO_WEB_COLLECT: ["Y021011002"],
			GMO: ["132"]
		},
		{
			code: TokenErrorCode.INVALID_CARD_NAME_VALUE,
			item: "card.name",
			description: "カード名義に不正な値が含まれています",
			ZEUS: ["02131117", "02131123"],
			YAMATO_WEB_COLLECT: ["Y021011004"],
			GMO: ["131"]
		},
		{
			code: TokenErrorCode.EXPIRATION_YEAR_REQUIRED,
			item: "card.expires.year",
			description: "有効期限（年）が未入力です",
			ZEUS: ["02130714"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.INVALID_EXPIRATION_YEAR_VALUE,
			item: "card.expires.year",
			description: "有効期限（年）の値が不正です",
			ZEUS: ["02130717"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.PAST_EXPIRATION_YEAR,
			item: "card.expires.year",
			description: "有効期限（年）の値が過去日など、不正な値です",
			ZEUS: ["02130725"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.EXPIRATION_MONTH_REQUIRED,
			item: "card.expires.month",
			description: "有効期限（月）が未入力です",
			ZEUS: ["02130814"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.INVALID_EXPIRATION_MONTH_VALUE,
			item: "card.expires.month",
			description: "有効期限（月）の値が不正です",
			ZEUS: ["02130817"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.INVALID_EXPIRATION_MONTH,
			item: "card.expires.month",
			description: "有効期限（月）の値が存在しない月などです",
			ZEUS: ["02130825"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.INVALID_EXPIRATION_DATE,
			item: "card.expires",
			description: "有効期限が不正です",
			ZEUS: ["02130922"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.EXPIRATION_DATE_REQUIRED,
			item: "card.expires",
			description: "有効期限が未入力です",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021011101"],
			GMO: ["110"]
		},
		{
			code: TokenErrorCode.INVALID_EXPIRATION_DATE_VALUE,
			item: "card.expires",
			description: "有効期限の値が不正です",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021011105"],
			GMO: ["111", "112", "113"],
			SB_PAYMENT: ["04004"]
		},
		{
			code: TokenErrorCode.EXPIRATION_DATE_PAST,
			item: "card.expires",
			description: "有効期限が過去日です",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021011171"]
		},
		{
			code: TokenErrorCode.SECURITY_CODE_REQUIRED,
			item: "card.security_code",
			description: "セキュリティーコードが未入力です",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021011301"]
		},
		{
			code: TokenErrorCode.INVALID_SECURITY_CODE,
			item: "card.security_code",
			description: "セキュリティーコードが不正です",
			ZEUS: ["02131017", "02131014"],
			YAMATO_WEB_COLLECT: ["Y021011304"],
			GMO: ["121"],
			SB_PAYMENT: ["04005"]
		},
		{
			code: TokenErrorCode.INVALID_SECURITY_CODE_LENGTH,
			item: "card.security_code",
			description: "セキュリティーコードが 3 または 4 桁以外です",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021011302"],
			GMO: ["122"],
			SB_PAYMENT: ["05005"]
		},
		{
			code: TokenErrorCode.INVALID_CONTENT_TYPE,
			item: "",
			description: "CONTENT-TYPE が 'text/xml' もしくは 'application/xml' 以外です",
			ZEUS: ["02030106"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.MISSING_OR_ZERO_CONTENT_LENGTH,
			item: "",
			description: "CONTENT-LENGTH が存在しないか、0 が指定されています",
			ZEUS: ["02030107"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.CONTENT_LENGTH_TOO_LARGE,
			item: "",
			description: "CONTENT-LENGTH が 1024 byte より大きいです",
			ZEUS: ["02030108"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.XML_DATA_NOT_SENT,
			item: "",
			description: "XML データが未送信",
			ZEUS: ["02030207"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.XML_DATA_TOO_LARGE,
			item: "",
			description: "XML データが 1024 byte より大きいです",
			ZEUS: ["02030208"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.XML_SYNTAX_ERROR,
			item: "",
			description: "XML データに構文エラーがあります",
			ZEUS: ["02030209"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.EMPTY_XML_ACTION,
			item: "",
			description: "XML の action が空です",
			ZEUS: ["02080114"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.INVALID_XML_ACTION,
			item: "",
			description: "無効な action が指定されています",
			ZEUS: ["02080115"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.MISSING_AUTH_CLIENTIP,
			item: "",
			description: "XML に authentication > clientip が存在しないです",
			ZEUS: ["02130110"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.INVALID_AUTH_CLIENTIP_FORMAT,
			item: "",
			description: "authentication > clientip のフォーマットが不正です",
			ZEUS: ["02130114"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.INVALID_AUTH_CLIENTIP,
			item: "",
			description: "不正な authentication > clientip が指定されています",
			ZEUS: ["02130117"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.INVALID_AUTH_CLIENTIP_REPEAT,
			item: "",
			description: "不正な authentication > clientip が指定されています",
			ZEUS: ["02130118"],
			YAMATO_WEB_COLLECT: []
		},
		{
			code: TokenErrorCode.MISSING_MERCHANT_CODE,
			item: "",
			description: "加盟店コードをセットしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021010201"]
		},
		{
			code: TokenErrorCode.INVALID_MERCHANT_CODE,
			item: "",
			description: "正しい加盟店コードをセットしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021010202"]
		},
		{
			code: TokenErrorCode.INVALID_MERCHANT_CODE_REPEAT,
			item: "",
			description: "正しい加盟店コードをセットしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021010204"]
		},
		{
			code: TokenErrorCode.MISSING_AUTHENTICATION_TYPE,
			item: "",
			description: "認証区分をセットしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021010301"]
		},
		{
			code: TokenErrorCode.INVALID_AUTHENTICATION_TYPE,
			item: "",
			description: "正しい認証区分をセットしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021010306"]
		},
		{
			code: TokenErrorCode.MISSING_OPTION_SERVICE_TYPE,
			item: "",
			description: "オプションサービス区分をセットしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021010401"]
		},
		{
			code: TokenErrorCode.INVALID_OPTION_SERVICE_TYPE,
			item: "",
			description: "正しいオプションサービス区分をセットしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021010406"]
		},
		{
			code: TokenErrorCode.MISSING_MEMBER_ID,
			item: "",
			description: "会員IDをセットしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021010501"]
		},
		{
			code: TokenErrorCode.INVALID_MEMBER_ID,
			item: "",
			description: "正しい会員IDをセットしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021010502"]
		},
		{
			code: TokenErrorCode.INVALID_MEMBER_ID_REPEAT,
			item: "",
			description: "正しい会員IDをセットしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021010504"]
		},
		{
			code: TokenErrorCode.INVALID_AUTH_KEY,
			item: "",
			description: "認証キーをセットしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021010601", "Y021010602"]
		},
		{
			code: TokenErrorCode.INVALID_AUTH_KEY,
			item: "",
			description: "正しい認証キーをセットしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021010602", "Y021010604"]
		},
		{
			code: TokenErrorCode.MISSING_CHECKSUM,
			item: "",
			description: "チェックサムをセットしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021010701"]
		},
		{
			code: TokenErrorCode.INVALID_CHECKSUM,
			item: "",
			description: "正しいチェックサムをセットしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021010771"]
		},
		{
			code: TokenErrorCode.INVALID_CARD_IDENTIFIER_KEY,
			item: "",
			description: "正しいカード識別キーをセットしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021010806"]
		},
		{
			code: TokenErrorCode.INVALID_LAST_USED_DATE,
			item: "",
			description: "正しい最終利用日時をセットしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y021011205"]
		},
		{
			code: TokenErrorCode.INVALID_URL_FORMAT,
			item: "",
			description: "正しい URL 形式にてリクエストしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y022000001"]
		},
		{
			code: TokenErrorCode.INVALID_AUTHENTICATION_TYPE_REQUEST,
			item: "",
			description: "正しい認証区分にてリクエストしてください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y022000002"]
		},
		{
			code: TokenErrorCode.CHECK_OPTION_SERVICE_CONTRACT,
			item: "",
			description: "オプションサービスの契約または、オプションサービス区分を確認してください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y022000003"]
		},
		{
			code: TokenErrorCode.MISSING_CARD_INFO,
			item: "",
			description: "カード情報がありません。会員番号等を確認してください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y022000004"]
		},
		{
			code: TokenErrorCode.TOKEN_ISSUE_FAILED_CHECK,
			item: "",
			description: "トークンの発行が出来ません。リクエスト内容を確認してください",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y022000005"]
		},
		{
			code: TokenErrorCode.CREDIT_PAYMENT_NOT_AVAILABLE,
			item: "",
			description: "クレジット決済は現在ご利用いただけません",
			ZEUS: [],
			YAMATO_WEB_COLLECT: ["Y022000006"]
		},
		{
			code: TokenErrorCode.TOKEN_ISSUE_FORMAT_ERROR_INVALID_CHARACTERS,
			item: "",
			description: "MPクレカトークン発行数フォーマットエラー(数字以外を含む)",
			GMO: ["141"]
		},
		{
			code: TokenErrorCode.TOKEN_ISSUE_FORMAT_ERROR_OUT_OF_RANGE,
			item: "",
			description: "MPクレカトークン発行数フォーマットエラー(1-10の範囲外)",
			GMO: ["142"]
		},
		{
			code: TokenErrorCode.ENCRYPTED_CARD_INFO_REQUIRED,
			item: "",
			description: "カード情報を暗号化した情報必須チェックエラー",
			GMO: ["150"]
		},
		{
			code: TokenErrorCode.DECRYPTION_FAILED,
			item: "",
			description: "カード情報(Encrypted)が復号できない",
			GMO: ["190"]
		},
		{
			code: TokenErrorCode.DECRYPTION_FORMAT_ERROR,
			item: "",
			description: "カード情報(Encrypted)復号化後フォーマットエラー",
			GMO: ["191"]
		},
		{
			code: TokenErrorCode.CALLBACK_REQUIRED_ERROR,
			item: "",
			description: "callback必須チェックエラー",
			GMO: ["200"]
		},
		{
			code: TokenErrorCode.CALLBACK_FORMAT_ERROR,
			item: "",
			description:
				"callbackフォーマットエラー(半角英数字、アンダースコア、ピリオド以外を含む)",
			GMO: ["201"]
		},
		{
			code: TokenErrorCode.MISSING_CARD_OBJECT,
			item: "",
			description: "MPクレカトークン用パラメーター(cardObject)が存在しない",
			GMO: ["701"]
		},
		{
			code: TokenErrorCode.MERCHANT_FORMAT_ERROR,
			item: "",
			description: "マーチャント ID フォーマットエラー",
			SB_PAYMENT: ["07001"]
		},
		{
			code: TokenErrorCode.SERVICE_ID_FORMAT_ERROR,
			item: "",
			description: " サービス ID フォーマットエラー",
			SB_PAYMENT: ["07002"]
		}
	]
];

/**
 * トークンエラーを取得する
 * @param errorCords トークン取得で発生した決済代行からのエラー配列
 * @param settings 決済システム初期化時の設定
 * @returns エラーオブジェクト
 */
export const getTokenError = (
	errorCords: Code[],
	settings?: CreditCardJsInitResponse,
	paymentSystemErrorCodes?: TokenErrorCode[]
): PaymentSystemError[] => {
	return getErrorObjects(
		errorCords,
		TokenErrorCode.UNKNOWN_ERROR,
		tokenCommonErrors,
		tokenUniqueErrors,
		settings,
		paymentSystemErrorCodes
	);
};
