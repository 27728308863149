import axios, { isAxiosError } from "axios";
import { urls } from "..";
import {
	PaymentSystemError,
	PaymentSystemReturnStatus,
	CreditCardDetail
} from "../../../types/v1/credit_card";
import { getCommonErrorObjects, CommonErrorCode } from "../error/commonError";
import { getTokenError, TokenErrorCode, unknownTokenError } from "../error/tokenError";
import { getAuthHeaders } from "../../common/getAuthHeaders";
import {
	GetTokenFailureResponse,
	CreditCardJsInitResponse,
	GetTokenOnFailure
} from "../../../types/v1/credit_card/get_token";

type Success = { isSuccess: true; card: CreditCardDetail };
type Failure = { isSuccess: false; errorObj: GetTokenFailureResponse };

type GetCreditCardsReturn = Success | Failure;

/**
 * idからクレジットカード情報を取得する
 * （決済システムサーバーから）
 * @param apiKey 認証キー
 * @param authValue 認証シグネチャ
 * @param id カードid
 * @param settings 決済システム設定
 * @param onFailure 失敗時のコールバック
 * @returns 取得成功時：カード情報, 失敗時：トークンエラー
 */
export const getCreditCardDetail = async (
	apiKey: string,
	authValue: string,
	id: string,
	settings: CreditCardJsInitResponse,
	onFailure?: GetTokenOnFailure
): Promise<GetCreditCardsReturn> => {
	/**
	 * `GetTokenFailureResponse`を作って返す
	 * 他のエラーは普通に書いてください
	 * @param paymentSystemErrors 決済システムエラーオブジェクト
	 * @returns トークンエラー
	 */
	const failureResponseHandler = (paymentSystemErrors: PaymentSystemError[]): Failure => {
		const failureResponse: GetTokenFailureResponse = {
			status: PaymentSystemReturnStatus.FAILURE,
			errors: paymentSystemErrors
		};

		if (onFailure) onFailure(failureResponse);
		return { isSuccess: false, errorObj: failureResponse };
	};

	try {
		const headers = getAuthHeaders(apiKey, authValue);

		const response = await axios.get<CreditCardDetail>(
			`${urls.PROTOCOL}://${urls.SERVER_DOMAIN}${urls.CREDIT_CARDS}/${id}`,
			{ headers }
		);

		return { isSuccess: true, card: response.data };
	} catch (error) {
		console.error("トークン取得中にエラーが発生しました:", String(error));

		if (isAxiosError(error)) {
			if (error.response) {
				// サーバーがステータスコードを返したが、リクエストがエラーであった場合
				switch (error.response.status) {
					case 401:
						return failureResponseHandler(
							getCommonErrorObjects([], settings, [CommonErrorCode.UN_AUTHORIZED])
						);
					case 404:
						return failureResponseHandler(
							getTokenError([], settings, [TokenErrorCode.UNDEFINED_ID])
						);
					default:
						return failureResponseHandler(
							getCommonErrorObjects([], settings, [CommonErrorCode.NETWORK_ERROR])
						);
				}
			} else {
				return failureResponseHandler(
					getCommonErrorObjects([], settings, [CommonErrorCode.NETWORK_ERROR])
				);
			}
		} else {
			// axios 以外のエラー
			console.error("Unexpected error:", String(error));
			return failureResponseHandler(unknownTokenError(error));
		}
	}
};
