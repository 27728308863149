import {
	PaymentRegistrationSuccessGmoResponse,
	PaymentRegistrationSuccessSbPaymentResponse,
	PaymentRegistrationSuccessYamatoResponse,
	PaymentRegistrationSuccessZeusResponse,
	PostPaymentRegistrationSuccessResponse
} from ".";
import { CreditAgency } from "../enum";
import { CreditCardJsInitResponse, AgencySettings } from "../get_token";
import { CreditCardGmoInitData } from "../gmo";
import { CreditCardSbPaymentInitData } from "../sbPayment";
import { CreditCardYamatoInitData } from "../yamato";
import { CreditCardZeusInitData } from "../zeus";

export type AgencyPair = {
	agency: CreditAgency | null;
	res: PostPaymentRegistrationSuccessResponse;
	settings: CreditCardJsInitResponse<AgencySettings>;
};

type ZeusPair = {
	agency: CreditAgency.ZEUS;
	res: PaymentRegistrationSuccessZeusResponse;
	settings: CreditCardJsInitResponse<CreditCardZeusInitData>;
};

type GmoPair = {
	agency: CreditAgency.GMO;
	res: PaymentRegistrationSuccessGmoResponse;
	settings: CreditCardJsInitResponse<CreditCardGmoInitData>;
};

type YamatoPair = {
	agency: CreditAgency.YAMATO_WEB_COLLECT;
	res: PaymentRegistrationSuccessYamatoResponse;
	settings: CreditCardJsInitResponse<CreditCardYamatoInitData>;
};

type SbPaymentPair = {
	agency: CreditAgency.SB_PAYMENT;
	res: PaymentRegistrationSuccessSbPaymentResponse;
	settings: CreditCardJsInitResponse<CreditCardSbPaymentInitData>;
};

type CombinedPair = ZeusPair | GmoPair | YamatoPair | SbPaymentPair;

export const isKnownAgencyPair = (pair: AgencyPair): pair is CombinedPair => {
	if (pair.res.agency !== pair.settings.agency || pair.res.agency !== pair.agency) {
		return false;
	}

	switch (pair.res.agency) {
		case CreditAgency.ZEUS:
		case CreditAgency.GMO:
		case CreditAgency.YAMATO_WEB_COLLECT:
		case CreditAgency.SB_PAYMENT:
			return true;
		default:
			return false;
	}
};
