/**
 * 決済システムが配信するJavaScriptファイルです！
 * 検証環境ではソースマップを付与した状態で配信しています。
 *
 * 不明点等あれば適宜、ご連絡ください( ・∇・)
 */

import axios from "axios";
import {
	CreditCardJs,
	GetSettings,
	PaymentSystemReturnStatus,
	GetCreditCardRequest,
	GetCreditCardsResponse,
	GetContinuationCards,
	PaymentSystemErrorObject,
	PaymentSystemError,
	GetPathCardResult,
	GetPostOrPathCardResultResponse,
	GetPostCardResult,
	CreditCardDetail,
	YamatoCreditCardDetail
} from "../../types/v1/credit_card";
import { CreditAgency, ThreeDomainSecureAuthType } from "../../types/v1/credit_card/enum";
import { creditZeusGetToken, zeusDoThreeDomainSecureAuth } from "./zeus";
import { creditGmoGetToken, gmoDoThreeDomainSecureAuth } from "./gmo";
import { creditYamatoGetToken, creditYamatoInit, yamatoDoThreeDomainSecureAuth } from "./yamato";
import { creditSbPaymentGetToken, sbPaymentDoThreeDomainSecureAuth } from "./sb_payment";
import {
	CommonErrorCode,
	failureRegistrationError,
	getCommonErrorObjects,
	invalidAgencyPairError,
	notInitError
} from "./error/commonError";
import creditCardType from "credit-card-type";
import { getCreditCardDetail } from "./functions/getCreditCard";
import { TokenErrorCode, getTokenError } from "./error/tokenError";
import {
	JsInitReturn,
	GetTokenSuccessResponse,
	GetTokenFailureResponse,
	CreditCardInit,
	CreditCardJsInitRequest,
	CreditCardJsInitResponse,
	GetToken,
	CardInfo,
	GetTokenOnSuccess,
	GetTokenOnFailure,
	GetTokenOptions,
	AgencySettings
} from "../../types/v1/credit_card/get_token";
import {
	DoThreeDomainSecureAuth,
	PostPaymentRegistrationSuccessResponse,
	AgencyPair,
	isKnownAgencyPair
} from "../../types/v1/credit_card/do_three_domain_secure_auth";

export const urls = {
	/** 決済システムサーバーのプロトコル */
	PROTOCOL: import.meta.env.VITE_SERVER_PROTOCOL,
	/** 決済システムサーバーのドメイン */
	SERVER_DOMAIN: import.meta.env.VITE_SERVER_DOMAIN,
	/** js初期化時の設定取得 */
	JS_INIT: "/api/v1/authentication/js-init",
	/** 決済時のカード情報一時保存 */
	PAYMENT_CARD_TEMPORARY: "/api/v1/credit/payment/card-temporary",
	/** 決済登録 */
	PAYMENT_REGISTRATION: "/api/v1/credit/payment/registration",
	/** 3Dセキュア認証画面リダイレクト先 */
	PAYMENT_AUTHENTICATION_REDIRECT: "/api/v1/credit/payment/authentication-redirect",
	/** 3Dセキュア認証コールバック */
	PAYMENT_AUTHENTICATION_RESULT: "/api/v1/credit/payment/authentication-result",
	/** オーソリ */
	PAYMENT_AUTHORIZATION: "/api/v1/credit/payment/authorization",
	/** キャプチャ */
	PAYMENT_CAPTURE: "/api/v1/credit/payment/capture",
	/** カード一覧 */
	CREDIT_CARDS: "/api/v1/credit/cards",
	/** web socket（クレカ用） */
	CREDIT_WEB_SOCKET: "/socket.io/credit"
};

/** 初期化が完了しているかどうか */
let isInitialized = false;
/** 決済システムの設定 */
let settings: JsInitReturn = {
	status: PaymentSystemReturnStatus.FAILURE,
	errors: getCommonErrorObjects([], undefined, [CommonErrorCode.NOT_SYSTEM_INIT])
};
/** マスク済みのカード情報 */
// eslint-disable-next-line prefer-const
let card: GetTokenSuccessResponse | GetTokenFailureResponse = {
	status: PaymentSystemReturnStatus.FAILURE,
	errors: []
};
/** 登録済みクレカ, 取得済みの場合、再通信をせずにこの値を返す */
let continuationCards: GetCreditCardsResponse | undefined = undefined;

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

/**
 * 配信したjsを初期化する関数
 * 利用中の決済代行ごとの設定も行う
 *
 * 決済システムの設定を取得し、返す
 * @param apiKey api認証key
 * @param authValue api認証keyとsecretを結合してSHA256でハッシュ化し、大文字に変換した文字列
 * @returns 決済システム設定情報
 */
const init: CreditCardInit = async (apiKey: string, authValue: string) => {
	if (isInitialized) return settings;

	const request: CreditCardJsInitRequest = {
		key: apiKey,
		auth_value: authValue,
		use_payment: "CREDIT_CARD"
	};

	try {
		const res = await axios.post<CreditCardJsInitResponse>(
			`${urls.PROTOCOL}://${urls.SERVER_DOMAIN}${urls.JS_INIT}`,
			request
		);

		settings = {
			...res.data,
			status: PaymentSystemReturnStatus.SUCCESS,
			api_key: apiKey,
			auth_value: authValue
		};
	} catch (e) {
		console.error(e);
		return {
			status: PaymentSystemReturnStatus.FAILURE,
			errors: getCommonErrorObjects([], undefined, [
				CommonErrorCode.GET_PAYMENT_SYSTEM_SETTINGS_FAILURE
			])
		};
	}

	switch (settings.agency) {
		case CreditAgency.YAMATO_WEB_COLLECT:
			await creditYamatoInit(settings);
			break;
	}

	isInitialized = true;
	return settings;
};

/**
 * 引数の顧客idで登録されたクレジットカード情報を取得する
 * @param apiKey api認証key
 * @param authValue api認証keyとsecretを結合してSHA256でハッシュ化し、大文字に変換した文字列
 * @param customerId 取得対象の顧客id
 * @param cardId 決済システムが発行したクレジットカード固有のid, 引数にある場合、対象のidのクレカ情報を一件返す
 * @returns 登録済みクレジットカード情報一覧
 */
const getContinuationCards: GetContinuationCards = async (
	apiKey: string,
	authValue: string,
	customerId: string,
	cardId?: string
) => {
	// 設定取得が完了していない
	if (settings.status === PaymentSystemReturnStatus.FAILURE) {
		return notInitError();
	}

	if (cardId) {
		const targetCard = continuationCards?.cards.filter(cardRow => cardRow.id === cardId)[0];

		if (targetCard) {
			return { status: PaymentSystemReturnStatus.SUCCESS, ...targetCard };
		} else {
			return { status: PaymentSystemReturnStatus.FAILURE, errors: [] };
		}
	} else {
		const params: GetCreditCardRequest = { customer_id: customerId };
		const headers = { "X-Auth-Key": apiKey, "X-Auth-Signature": authValue };

		const response = await axios.get<GetCreditCardsResponse>(
			`${urls.PROTOCOL}://${urls.SERVER_DOMAIN}${urls.CREDIT_CARDS}`,
			{ params, headers }
		);

		continuationCards = response.data;
		return { status: PaymentSystemReturnStatus.SUCCESS, ...continuationCards };
	}
};

/**
 * 決済システムの基本設定を取得する
 *
 * 初期化済みの場合に取得可能
 * @returns 基本設定
 */
const getSettings: GetSettings = () => {
	return settings;
};

/**
 * クレジットカード情報を元に後続処理に必要なトークンを取得する
 *
 * 利用中の決済代行ごとに処理を分岐する
 * @param cardInfo カード情報
 * @param onSuccess token取得成功時のコールバック
 * @param onFailure token取得失敗時のコールバック
 */
const getToken: GetToken = async (
	cardInfo: CardInfo,
	apiKey: string,
	authValue: string,
	onSuccess?: GetTokenOnSuccess,
	onFailure?: GetTokenOnFailure,
	options?: GetTokenOptions
) => {
	const failureHandler = (
		errorObj: PaymentSystemErrorObject | PaymentSystemError[]
	): PaymentSystemErrorObject => {
		const failureResponse: PaymentSystemErrorObject = {
			status: PaymentSystemReturnStatus.FAILURE,
			errors: Array.isArray(errorObj) ? errorObj : errorObj.errors
		};
		console.log(failureResponse);

		if (onFailure) onFailure(failureResponse);
		return failureResponse;
	};

	// 設定取得が完了していない
	if (settings.status === PaymentSystemReturnStatus.FAILURE) {
		return failureHandler(notInitError());
	}
	// セキュリティーコード未入力
	if (settings.is_use_card_verification_value && !cardInfo.security_code) {
		return failureHandler(getTokenError([], settings, [TokenErrorCode.SECURITY_CODE_REQUIRED]));
	}

	const cardId = cardInfo.card_id;
	let savedCardDetail: CreditCardDetail | undefined;

	// 登録済みクレカを利用する場合
	if (cardId && !options?.isProvisionalCard) {
		/** カード情報詳細レスポンス */
		const response = await getCreditCardDetail(apiKey, authValue, cardId, settings, onFailure);

		if (response.isSuccess) {
			cardInfo = {
				card_id: cardInfo.card_id,
				name: response.card.name,
				// マスク済みのカード番号をそのまま代入（関数内で使わないので）
				number: response.card.number.masked,
				expires: response.card.expires,
				brand: response.card.brand,
				security_code: cardInfo.security_code
				// is_continuation: cardInfo.is_continuation
			};

			savedCardDetail = response.card;
		} else {
			return response.errorObj;
		}
	} else {
		try {
			cardInfo.brand = creditCardType(String(cardInfo.number))[0]?.type || "unknown";
		} catch {
			cardInfo.brand = "unknown";
		}
	}

	switch (settings.agency) {
		case CreditAgency.YAMATO_WEB_COLLECT:
			return creditYamatoGetToken(
				cardInfo,
				settings,
				options,
				savedCardDetail as YamatoCreditCardDetail | undefined,
				onSuccess,
				onFailure
			);
		case CreditAgency.ZEUS:
			return creditZeusGetToken(cardInfo, settings, onSuccess, onFailure);
		case CreditAgency.GMO:
			return creditGmoGetToken(cardInfo, settings, onSuccess, onFailure);
		case CreditAgency.SB_PAYMENT:
			return creditSbPaymentGetToken(
				cardInfo,
				settings,
				options?.purchaseInfo,
				onSuccess,
				onFailure
			);
		default:
			return failureHandler({
				status: PaymentSystemReturnStatus.FAILURE,
				errors: getCommonErrorObjects([], undefined, [CommonErrorCode.UNKNOWN_AGENCY])
			});
	}
};

/**
 * 決済登録成功後、3ds認証画面の表示と完了後のコールバック処理を実施する
 *
 * @param res 決済登録結果のレスポンス内容
 * @param onSuccess 3ds認証成功時のコールバック（認証自体の成否ではなく、処理が成功したかどうかのこと）
 * @param onFailure 3ds認証失敗時のコールバック（認証自体の成否ではなく、処理が成功したかどうかのこと）
 * @param initializedSettings 決済システムの設定引数に値がある場合はそっちを優先する
 */
const doThreeDomainSecureAuth: DoThreeDomainSecureAuth = async (
	res,
	onSuccess,
	onFailure,
	options = { authType: ThreeDomainSecureAuthType.POP },
	initializedSettings = settings
) => {
	if (initializedSettings.status === PaymentSystemReturnStatus.FAILURE) {
		return notInitError();
	}

	if (res.status === PaymentSystemReturnStatus.FAILURE) {
		return failureRegistrationError();
	}

	/**
	 * pop表示（別タブ）の場合の処理
	 * @returns トランザクション情報の`promise`
	 */
	const doPop = (
		successRes: PostPaymentRegistrationSuccessResponse,
		successSettings: CreditCardJsInitResponse<AgencySettings>
	) => {
		const pair: AgencyPair = {
			agency: successRes.agency,
			res: successRes,
			settings: successSettings
		};

		if (!isKnownAgencyPair(pair)) {
			return invalidAgencyPairError();
		}

		switch (pair.agency) {
			case CreditAgency.ZEUS:
				return zeusDoThreeDomainSecureAuth(pair.res, pair.settings, onSuccess, onFailure);
			case CreditAgency.GMO:
				return gmoDoThreeDomainSecureAuth(pair.res, pair.settings, onSuccess, onFailure);
			case CreditAgency.YAMATO_WEB_COLLECT:
				return yamatoDoThreeDomainSecureAuth(pair.res, pair.settings, onSuccess, onFailure);
			case CreditAgency.SB_PAYMENT:
				return sbPaymentDoThreeDomainSecureAuth(
					pair.res,
					pair.settings,
					onSuccess,
					onFailure
				);
		}
	};

	/**
	 * 同一ページでリダイレクトする場合の処理
	 * @returns トランザクション情報（ただし、リダイレクトのため実質的に`void`）
	 */
	const doRedirect = (
		successRes: PostPaymentRegistrationSuccessResponse,
		successSettings: CreditCardJsInitResponse<AgencySettings>
	) => {
		const queryParams = new URLSearchParams({
			transaction_system_id: successRes.transaction_id,
			key: successSettings.api_key,
			redirect_url: options.redirectUrl as string,
			params: JSON.stringify(successRes)
		}).toString();

		window.location.href = `${urls.PROTOCOL}://${urls.SERVER_DOMAIN}${urls.PAYMENT_AUTHENTICATION_REDIRECT}?${queryParams}`;
		return successRes;
	};

	switch (options.authType) {
		case ThreeDomainSecureAuthType.REDIRECT:
			return doRedirect(res, initializedSettings);
		case ThreeDomainSecureAuthType.POP:
			return doPop(res, initializedSettings);
		default:
			return res;
	}
};

/**
 * 登録済みクレカ情報変更結果を取得する
 *
 * 成功した場合は変更後のカード情報（GET /card で取得できるものと同じ情報）
 * 3Dセキュアに失敗した場合や、与信確認に失敗した場合はその旨がreturnされる
 * @param apiKey api認証key
 * @param authValue api認証keyとsecretを結合してSHA256でハッシュ化し、大文字に変換した文字列
 * @param transactionId `doThreeDomainSecureAuth`関数の返り値の`id`, 3Dセキュア完了まで有効な値
 * @param returnAt `doThreeDomainSecureAuth`関数の返り値の`return_at`, 3Dセキュア完了まで有効な値
 * @param cardId 変更対象の`card_id`
 * @returns 変更結果
 */
const getPathCardResult: GetPathCardResult = async (
	apiKey,
	authValue,
	transactionId,
	returnAt,
	cardId
) => {
	const headers = { "X-Auth-Key": apiKey, "X-Auth-Signature": authValue };
	const params = { transaction_system_id: transactionId, return_at: returnAt };

	const response = await axios.get<GetPostOrPathCardResultResponse | PaymentSystemErrorObject>(
		`${urls.PROTOCOL}://${urls.SERVER_DOMAIN}${urls.CREDIT_CARDS}/${cardId}/result`,
		{ params, headers }
	);

	return response.data;
};

/**
 * クレカ新規登録結果を取得する
 *
 * 成功した場合は変更後のカード情報（GET /card で取得できるものと同じ情報）
 * 3Dセキュアに失敗した場合や、与信確認に失敗した場合はその旨がreturnされる
 * @param apiKey api認証key
 * @param authValue api認証keyとsecretを結合してSHA256でハッシュ化し、大文字に変換した文字列
 * @param transactionId `doThreeDomainSecureAuth`関数の返り値の`id`, 3Dセキュア完了まで有効な値
 * @param returnAt `doThreeDomainSecureAuth`関数の返り値の`return_at`, 3Dセキュア完了まで有効な値
 * @returns 登録結果
 */
const getPostCardResult: GetPostCardResult = async (apiKey, authValue, transactionId, returnAt) => {
	const headers = { "X-Auth-Key": apiKey, "X-Auth-Signature": authValue };
	const params = { transaction_system_id: transactionId, return_at: returnAt };

	const response = await axios.get<GetPostOrPathCardResultResponse | PaymentSystemErrorObject>(
		`${urls.PROTOCOL}://${urls.SERVER_DOMAIN}${urls.CREDIT_CARDS}/result`,
		{ params, headers }
	);

	return response.data;
};

// おまじない
export {};

declare global {
	interface Window {
		LeghornPayment: CreditCardJs;
	}
}

window.LeghornPayment = {
	urls,
	card,
	init,
	getContinuationCards,
	getToken,
	getSettings,
	doThreeDomainSecureAuth,
	getPathCardResult,
	getPostCardResult
};
