import { io } from "socket.io-client";
import { PaymentSystemReturnStatus } from "../../../types/v1/credit_card";
import { urls } from "..";
import { CreditCardGmoInitData } from "../../../types/v1/credit_card/gmo";
import { CreditCardJsInitResponse } from "../../../types/v1/credit_card/get_token";
import {
	PaymentRegistrationSuccessGmoResponse,
	DoThreeDomainSecureAuthOnSuccess,
	DoThreeDomainSecureAuthOnFailure,
	DoThreeDomainSecureAuthReturn,
	DoThreeDomainSecureAuthIncomingMessage
} from "../../../types/v1/credit_card/do_three_domain_secure_auth";

export const gmoDoThreeDomainSecureAuth = async (
	res: PaymentRegistrationSuccessGmoResponse,
	settings: CreditCardJsInitResponse<CreditCardGmoInitData>,
	options: { popupWindow: WindowProxy | null },
	onSuccess?: DoThreeDomainSecureAuthOnSuccess,
	onFailure?: DoThreeDomainSecureAuthOnFailure
): Promise<DoThreeDomainSecureAuthReturn> => {
	console.log(res, settings);

	let newTab = options.popupWindow;
	if (!newTab) {
		// options.popupWindow が未指定の場合に新しいウィンドウを開く
		newTab = window.open("", "_blank");
	}

	if (newTab) {
		newTab.location.href = res.three_domain_secure_auth_url;
	} else {
		console.error(
			"新しいウィンドウを開けませんでした。ポップアップブロッカーを確認してください。"
		);
	}

	return new Promise<DoThreeDomainSecureAuthReturn>((resolve, reject) => {
		/** WebSocketのインスタンス */
		const socket = io(`${urls.PROTOCOL}://${urls.SERVER_DOMAIN}${urls.CREDIT_WEB_SOCKET}`, {
			query: { transaction_id: res.transaction_id, return_at: res.return_at }
		});

		socket.on("connect", () => {
			console.log("WebSocket接続完了");
		});

		socket.on("connect_error", error => {
			console.error("WebSocket接続失敗:", error);

			socket.disconnect();

			if (onFailure) {
				onFailure([]);
			}
			reject(error);
		});

		// 3Dセキュア認証結果受け取り
		socket.on("3dsResult", (result: DoThreeDomainSecureAuthIncomingMessage) => {
			console.log("3Dセキュア認証結果", result);

			if (newTab) {
				// 認証結果を受け取った後、タブを閉じる
				newTab.close();
			}

			const returnObject: DoThreeDomainSecureAuthReturn = {
				...result,
				status: PaymentSystemReturnStatus.SUCCESS
			};

			socket.disconnect();

			if (onSuccess) {
				onSuccess(returnObject);
			}

			resolve(returnObject);
		});
	});
};
