import axios from "axios";
import { PaymentSystemReturnStatus } from "../../../types/v1/credit_card";
import { CreditAgency } from "../../../types/v1/credit_card/enum";
import {
	CreditCardSbPaymentInitData,
	TokenResponseFailure,
	GetTokenCallbackObject,
	TokenResponseSuccess,
	ComSbPaymentSystem,
	TokenRequest,
	TokenResponseResult,
	ComSbpsSystemTds2,
	TdsTokenRequest,
	TdsTokenResponse,
	TdsTokenResponseSuccess,
	TdsTokenResponseFailure
} from "../../../types/v1/credit_card/sbPayment";
import { formatExpire } from "../../common/formatExpire";
import { loadExternalScript } from "../../common/loadExternalScript";
import { sbPaymentEndpoints } from "../../endpoint/sbPayment";
import { getTokenError, TokenErrorCode } from "../error/tokenError";
import { maskCardNumber, maskSecurityCode } from "../util";
import { urls } from "..";
import { getAuthHeaders } from "../../common/getAuthHeaders";
import {
	CardInfo,
	CreditCardJsInitResponse,
	GetTokenOnSuccess,
	GetTokenOnFailure,
	GetTokenSuccessResponse,
	GetTokenFailureResponse
} from "../../../types/v1/credit_card/get_token";
import { PurchaseInfo } from "../../../types/v1/credit_card/get_token/purchaseInfo";

declare global {
	interface Window {
		com_sbps_system: ComSbPaymentSystem;
		com_sbps_system_tds2: ComSbpsSystemTds2;
	}
}

interface PaymentCardTemporary {
	/** SB PAYMENTの場合 */
	[CreditAgency.SB_PAYMENT]?: {
		token: string;
		token_key: string;
		tds_info_token: string;
		tds_info_token_key: string;
	};
}

class GetTokenError extends Error {
	public tokenError: TokenResponseFailure | TdsTokenResponseFailure;

	constructor(tokenErrorObj: TokenResponseFailure | TdsTokenResponseFailure, message?: string) {
		super(message && message);

		this.tokenError = tokenErrorObj;
	}
}

/**
 * トークン取得結果コードが成功系かどうかを確認する型ガード
 * @param res トークン取得結果
 * @returns resultCode === TokenResponseResult.OKの時、`TokenResponseSuccess`型とみなす
 */
const isGetTdsTokenCallbackSuccessObjectSbPayment = (
	res: TdsTokenResponse
): res is TdsTokenResponseSuccess => {
	return res.result === TokenResponseResult.OK;
};

/**
 * トークン取得結果コードが成功系かどうかを確認する型ガード
 * @param res トークン取得結果
 * @returns resultCode === TokenResponseResult.OKの時、`TokenResponseSuccess`型とみなす
 */
const isGetTokenCallbackSuccessObjectSbPayment = (
	res: GetTokenCallbackObject
): res is TokenResponseSuccess => {
	return res.result === TokenResponseResult.OK;
};

/**
 * カード利用者情報トークン（3Dセキュア認証用情報送信）を取得する
 * @param settings 決済設定
 * @param purchaseInfo 購入者情報
 * @returns カード利用者トークン
 */
const getTdsToken = (
	settings: CreditCardJsInitResponse<CreditCardSbPaymentInitData>,
	purchaseInfo: PurchaseInfo
) => {
	const { bill } = purchaseInfo;

	const request: TdsTokenRequest = {
		merchantId: settings.merchant_id,
		serviceId: settings.service_id,
		billingLastName: bill?.name.last || "",
		billingFirstName: bill?.name.first || "",
		email: bill?.email || ""
	};

	return new Promise<TdsTokenResponse>((resolve, reject) => {
		const callback = (res: TdsTokenResponse) => {
			try {
				if (isGetTdsTokenCallbackSuccessObjectSbPayment(res)) {
					resolve(res);
				} else {
					reject(res);
				}
			} catch (e) {
				console.error(e);
				throw e;
			}
		};

		try {
			window.com_sbps_system_tds2.generateToken(request, callback);
		} catch (e) {
			throw e;
		}
	});
};

/**
 * カード一時情報を送信するハンドラー
 * @param settings 決済設定
 * @param tokenRes カード情報トークン化レスポンス
 * @param tdsTokenRes カード利用者情報トークン
 * @returns 送信結果
 */
const handleCardTemporary = async (
	settings: CreditCardJsInitResponse<CreditCardSbPaymentInitData>,
	tokenRes: TokenResponseSuccess,
	tdsTokenRes: TdsTokenResponseSuccess
) => {
	const cardTemporaryRequest: PaymentCardTemporary = {
		[CreditAgency.SB_PAYMENT]: {
			token: tokenRes.tokenResponse.token,
			token_key: tokenRes.tokenResponse.tokenKey,
			tds_info_token: tdsTokenRes.tokenResponse.tds2infoToken,
			tds_info_token_key: tdsTokenRes.tokenResponse.tds2infoTokenKey
		}
	};

	const cardTmpResponse = await axios.post<{ id: string }>(
		`${urls.PROTOCOL}://${urls.SERVER_DOMAIN}${urls.PAYMENT_CARD_TEMPORARY}`,
		cardTemporaryRequest,
		{ headers: { ...getAuthHeaders(settings.api_key, settings.auth_value) } }
	);

	return cardTmpResponse.data;
};

/**
 * カード情報を基にSBPaymentトークンを取得する
 *
 * `window.com_sbps_system.getToken`関数はコールバック関数でしか結果を取得できないため、Promiseを生成し、コールバック内で`resolve`か`reject`しています。
 * 必ず、そのような実装をキープしてください
 * @param cardInfo カード情報（番号、セキュリティコード、名前、有効期限など）
 * @param settings 決済システム設定情報（SBPayment用のMerchant ID、Service IDなどを含む）
 * @param purchaseInfo 購入者情報
 * @param onSuccess トークン発行成功時のコールバック関数
 * @param onFailure トークン発行失敗時のコールバック関数
 * @returns トークン関連情報（成功または失敗の詳細を含む）
 */
export const creditSbPaymentGetToken = async (
	cardInfo: CardInfo,
	settings: CreditCardJsInitResponse<CreditCardSbPaymentInitData>,
	purchaseInfo: PurchaseInfo | undefined,
	onSuccess?: GetTokenOnSuccess,
	onFailure?: GetTokenOnFailure
): Promise<GetTokenSuccessResponse | GetTokenFailureResponse> => {
	if (!purchaseInfo) {
		return {
			status: PaymentSystemReturnStatus.FAILURE,
			errors: getTokenError([], settings, [TokenErrorCode.UNDEFINED_PURCHASE_INFO])
		};
	}

	await Promise.all([
		await loadExternalScript(
			sbPaymentEndpoints.generateToken[settings.environment],
			"head",
			"sbps-js"
		),
		await loadExternalScript(
			sbPaymentEndpoints.generateTdsToken[settings.environment],
			"head",
			"sbps-js-tds"
		)
	]);

	const format = formatExpire({
		year: { number: cardInfo.expires.year, length: 4 },
		month: { number: cardInfo.expires.month, padding: true }
	});

	const tokenRequest: TokenRequest = {
		merchantId: settings.merchant_id,
		serviceId: settings.service_id,
		ccNumber: cardInfo.number,
		ccExpiration: `${format.year}${format.month}`,
		...(settings.is_use_card_verification_value && {
			securityCode: cardInfo.security_code
		})
	};

	return new Promise((resolve, reject) => {
		const callback = async (res: GetTokenCallbackObject) => {
			try {
				if (isGetTokenCallbackSuccessObjectSbPayment(res)) {
					/** カード利用者情報トークン */
					const tdsRes = await getTdsToken(settings, purchaseInfo);

					if (isGetTdsTokenCallbackSuccessObjectSbPayment(tdsRes)) {
						await handleCardTemporary(settings, res, tdsRes);

						const successResponse: GetTokenSuccessResponse = {
							status: PaymentSystemReturnStatus.SUCCESS,
							token: res.tokenResponse.token,
							masked_security_code: maskSecurityCode(cardInfo.security_code),
							number: maskCardNumber(cardInfo.number),
							name: cardInfo.name,
							expires: { ...cardInfo.expires },
							brand: cardInfo.brand
						};
						window.LeghornPayment.card = successResponse;

						if (onSuccess) onSuccess(successResponse);
						resolve(successResponse);
					} else {
						throw new GetTokenError(tdsRes);
					}
				} else {
					throw new GetTokenError(res);
				}
			} catch (e) {
				const failureResponse: GetTokenFailureResponse = {
					status: PaymentSystemReturnStatus.FAILURE,
					errors: []
				};

				if (e instanceof GetTokenError) {
					const failureRes: TokenResponseFailure = e.tokenError;
					failureResponse.errors = getTokenError([failureRes.errorCode], settings);
				} else {
					failureResponse.errors = getTokenError([], settings, [
						TokenErrorCode.UNKNOWN_ERROR
					]);
				}

				window.LeghornPayment.card = failureResponse;

				if (onFailure) onFailure(failureResponse);
				reject(failureResponse);
			}
		};

		window.com_sbps_system?.generateToken(tokenRequest, callback);
	});
};
