// TODO: 流石に分ける、、、？（→進行中）
// 分ける場合はバックエンドと同じ、処理/共通 || 決済代行 の形式に

import { CreditCardTypeCardBrandId } from "credit-card-type/dist/types";
import { CreditAgency } from "./enum";
import {
	JsInitReturn,
	GetTokenSuccessResponse,
	GetTokenFailureResponse,
	CreditCardInit,
	GetToken
} from "./get_token";
import { DoThreeDomainSecureAuth } from "./do_three_domain_secure_auth";

/** 決済代行が投げてきたエラーコード */
export type Code = string | number;

export enum PaymentSystemReturnStatus {
	SUCCESS = "success",
	FAILURE = "failure"
}

export type PaymentSystemError = {
	/** 決済システムエラーコード */
	code: string;
	/** エラー箇所 */
	item: string;
	/** エラー詳細 */
	description: string;
	/** 決済システムが投げてきた元のエラーコード */
	origin_code?: Code;
};

export interface CreditCardError extends Partial<Record<CreditAgency, Code[]>> {
	/** 決済システムエラーコード */
	code: string;
	/** エラー箇所 */
	item: string;
	/** エラー詳細 */
	description: string;
}

/** 配信jsのエラーreturn定義 */
export interface PaymentSystemErrorObject {
	/** 処理結果 `failure`  */
	status: PaymentSystemReturnStatus.FAILURE;
	/** エラー内容 */
	errors: PaymentSystemError[];
}

// ------------------------------------------------------------ //
// 登録済みクレカ取得
// ------------------------------------------------------------ //

/** マスク済みのカード情報 */
export interface CardNumber {
	/** 上２桁 */
	prefix: number | string;
	/** 下４桁 */
	suffix: number | string;
	/** 上２桁、下４桁の間のマスク値 */
	masked: string;
}
/** カード有効期限 */
export interface CardExpires {
	/** 年 */
	year: number | string;
	/** 月 */
	month: number | string;
}

interface CreditCard {
	id: string;
	name: string;
	number: CardNumber;
	expires: CardExpires;
	/** カードブランド */
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	brand: CreditCardTypeCardBrandId | string;
	last_used_at: string;
}

/** 保存済みクレカ一覧取得リクエスト */
export interface GetCreditCardRequest {
	customer_id: string;
}

/** 保存済みクレカ一覧レスポンス */
export interface GetCreditCardsResponse {
	customer_id: string;
	cards: CreditCard[];
}

/** 保存済みクレカ詳細レスポンス */
export interface CreditCardDetailCommon extends CreditCard {
	created_at: string;
	updated_at: string;
	customer_id: string;
	agency: CreditAgency;
}

/** 決済代行がzeusの時に作られたカードのカード情報詳細 */
export interface ZeusCreditCardDetail extends CreditCardDetailCommon {
	agency: CreditAgency.ZEUS;
	send_id: string;
	send_point: string;
}

/** 決済代行がヤマトの時に作られたカードのカード情報詳細 */
export interface YamatoCreditCardDetail extends CreditCardDetailCommon {
	agency: CreditAgency.YAMATO_WEB_COLLECT;
	member_id: string;
	auth_key: string;
	last_credit_date: string;
}

/** クレカ情報詳細 */
export type CreditCardDetail = ZeusCreditCardDetail | YamatoCreditCardDetail;

/** 引数にカードidがあった場合のレスポンス */
export type GetCreditCardResponse = CreditCard | undefined;

/** クレカ取得成功return */
export type GetContinuationCardsSuccess = (GetCreditCardsResponse | GetCreditCardResponse) & {
	status: PaymentSystemReturnStatus.SUCCESS;
};

/** クレカ取得失敗return */
export interface GetContinuationCardsFailure extends PaymentSystemErrorObject {}

/** クレカ取得return */
type GetContinuationCardsReturn = GetContinuationCardsSuccess | GetContinuationCardsFailure;

export type GetContinuationCards = (
	/** 認証キー */
	apiKey: string,
	/** 認証シグネチャ, 認証key, valueを結合してsha256でハッシュ化して大文字にしたやつ */
	authValue: string,
	/** 検索対象の顧客id */
	customerId: string,
	/** 決済システムが発行したクレジットカード固有のid */
	cardId?: string
) => Promise<GetContinuationCardsReturn>;

export interface GetPostOrPathCardResultResponse {
	status: PaymentSystemReturnStatus.SUCCESS;
	card: CreditCardDetail;
}

export type GetPathCardResult = (
	/** 認証キー */
	apiKey: string,
	/** api認証シグネチャ */
	authValue: string,
	/** 3Dセキュア認証用トランザクションid */
	id: string,
	/** 3Dセキュア認証用トランザクションidの発行時間（文字列） */
	returnAt: string,
	/** 変更対象のカードid */
	cardId: string
) => Promise<GetPostOrPathCardResultResponse | PaymentSystemErrorObject>;

export type GetPostCardResult = (
	/** 認証キー */
	apiKey: string,
	/** api認証シグネチャ */
	authValue: string,
	/** 3Dセキュア認証用トランザクションid */
	id: string,
	/** 3Dセキュア認証用トランザクションidの発行時間（文字列） */
	returnAt: string
) => Promise<GetPostOrPathCardResultResponse | PaymentSystemErrorObject>;

// ------------------------------------------------------------ //

/** 設定取得関数 */
export type GetSettings = () => JsInitReturn;

/** クレカ用配信jsの提供関数定義 */
export interface CreditCardJs {
	urls: Record<string, string | undefined>;
	card: GetTokenSuccessResponse | GetTokenFailureResponse;
	init: CreditCardInit;
	getContinuationCards: GetContinuationCards;
	getToken: GetToken;
	getSettings: GetSettings;
	doThreeDomainSecureAuth: DoThreeDomainSecureAuth;
	getPathCardResult: GetPathCardResult;
	getPostCardResult: GetPostCardResult;
}
