import { zeusJsFileEndpoints } from "../../endpoint/zeus";
import { loadExternalScript } from "../../common/loadExternalScript";
import { PaymentSystemReturnStatus } from "../../../types/v1/credit_card";
import { urls } from "..";
import { io } from "socket.io-client";
import { insertOrUpdateElement } from "../../common/insertOrUpdateElement";
import { CreditCardZeusInitData } from "../../../types/v1/credit_card/zeus";
import { CreditCardJsInitResponse } from "../../../types/v1/credit_card/get_token";
import {
	PaymentRegistrationSuccessZeusResponse,
	DoThreeDomainSecureAuthOnSuccess,
	DoThreeDomainSecureAuthOnFailure,
	DoThreeDomainSecureAuthReturn,
	DoThreeDomainSecureAuthIncomingMessage,
	ZeusPopOptions
} from "../../../types/v1/credit_card/do_three_domain_secure_auth";

declare global {
	interface Window {
		/** zeus提供jsの定義 */
		setPareqParams: (
			md: string,
			paReq: "paReq",
			termUrl: string,
			threeDSMethod: 2,
			iframeUrl: string
		) => void;
		_onError: (error: { message: string }) => void;
		loadedChallenge: () => void;
		_onPaResSuccess: (data: unknown) => void;
	}
}

/**
 * zeus向けの3Dセキュア処理を実施する
 *
 * Promiseオブジェクトを返すので、必ず、処理の終端で `resolve` か `reject` するようにしてください
 * @param res 決済登録結果
 * @param settings 決済システムの設定
 * @param onSuccess 成功時のコールバック（任意）
 * @param onFailure 失敗時のコールバック（任意）
 * @returns トランザクション情報
 */
export const zeusDoThreeDomainSecureAuth = async (
	res: PaymentRegistrationSuccessZeusResponse,
	settings: CreditCardJsInitResponse<CreditCardZeusInitData>,
	zeusPopOptions?: ZeusPopOptions,
	onSuccess?: DoThreeDomainSecureAuthOnSuccess,
	onFailure?: DoThreeDomainSecureAuthOnFailure
): Promise<DoThreeDomainSecureAuthReturn> => {
	console.log(res, settings);

	let scriptSrc = "";

	if (settings.is_use_three_domain_secure) {
		scriptSrc = settings.is_use_card_verification_value
			? zeusJsFileEndpoints.withToken.withSecurityCode
			: zeusJsFileEndpoints.withToken.withoutSecurityCode;
	} else {
		scriptSrc = settings.is_use_card_verification_value
			? zeusJsFileEndpoints.withoutToken.withSecurityCode
			: zeusJsFileEndpoints.withoutToken.withoutSecurityCode;
	}

	// zeus提供jsを読み込む, 失敗した場合は処理を終了（何もできないので）
	try {
		await loadExternalScript(scriptSrc, "head");
	} catch (e) {
		throw new Error(String(e));
	}

	// エラーコールバックの設定
	if (zeusPopOptions?.callbacks?.onError) {
		window._onError = zeusPopOptions?.callbacks?.onError;
	}

	// 3Dセキュア認証成功コールバックの設定
	if (zeusPopOptions?.callbacks?.onSuccess) {
		window._onPaResSuccess = zeusPopOptions?.callbacks?.onSuccess;
	}

	// チャレンジ画面表示完了コールバックの設定
	if (zeusPopOptions?.callbacks?.onChallengeLoaded) {
		window.loadedChallenge = zeusPopOptions?.callbacks?.onChallengeLoaded;
	}

	const tdsContainer = insertOrUpdateElement(
		"3dscontainer",
		"div",
		zeusPopOptions?.tdsContainer?.attributes
	);

	return new Promise<DoThreeDomainSecureAuthReturn>((resolve, reject) => {
		/** WebSocketのインスタンス */
		const socket = io(`${urls.PROTOCOL}://${urls.SERVER_DOMAIN}${urls.CREDIT_WEB_SOCKET}`, {
			query: { transaction_id: res.transaction_id, return_at: res.return_at }
		});

		// WebSocket接続完了後、後続の処理を行う
		socket.on("connect", () => {
			console.log("WebSocket接続完了");

			// PaReq
			window.setPareqParams(
				res.xid,
				"paReq",
				`${urls.PROTOCOL}://${urls.SERVER_DOMAIN}${urls.PAYMENT_AUTHENTICATION_RESULT}?transaction_system_id=${res.transaction_id}&agency=${res.agency}&return_at=${res.return_at}&environment=${settings.environment}`,
				2,
				res.three_domain_secure_auth_url
			);
			console.log("PaReq送信完了");
		});

		// WebSocket接続失敗時
		socket.on("connect_error", error => {
			console.error("WebSocket接続失敗:", error);

			socket.disconnect();

			if (onFailure) {
				onFailure([]);
			}
			reject(error);
		});

		// 3Dセキュア認証結果受け取り
		socket.on("3dsResult", (result: DoThreeDomainSecureAuthIncomingMessage) => {
			console.log("3Dセキュア認証結果", result);

			const returnObject: DoThreeDomainSecureAuthReturn = {
				...result,
				status: PaymentSystemReturnStatus.SUCCESS
			};

			socket.disconnect();

			if (onSuccess) {
				onSuccess(returnObject);
			}

			if (tdsContainer) {
				tdsContainer.remove();
			}

			resolve(returnObject);
		});
	});
};
